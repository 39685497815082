#skills > h2 {
    font-size: 4rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
    font-style: italic;
    display: flex;
    justify-content: center;
}

.skills-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin: 1rem;
}

.skill {
    background-color: rgb(50, 50, 50);
    color: white;
    border-radius: 1.5rem;
    padding: 2rem;
    box-shadow: rgba(255, 255, 255, 0.25) 0px 14px 28px, rgba(255, 255, 255, 0.22) 0px 10px 10px;
}

.skill-header {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: rgb(181, 238, 255);
    padding-bottom: 0.5rem;
}

.skill-logo {
    font-size: 5rem;
}

.skill-name {
    font-size: 3rem;
}

.skill-level {
    font-style: italic;
    font-size: 1.2rem;
    color: antiquewhite;
}

.skill-description {
    font-size: 1.5rem;
}

@media (max-width: 1000px) {
    #skills > h2 {
        font-size: 3rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 800px) {
    .skill-logo {
        font-size: 4rem;
    }

    .skill-name {
        font-size: 2rem;
    }
}

@media (max-width: 650px) {
    .skills-grid {
        grid-template-columns: 1fr 1fr;
    }

    .skill {
        padding: 1.5rem;
    }

    .skill-logo {
        font-size: 3rem;
    }

    .skill-name {
        font-size: 2rem;
    }
}

@media (max-width: 570px) {
    #skills > h2 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
    }

    .skills-grid {
        gap: 0.5rem;
        margin: 0.5rem;
    }

    .skill {
        padding: 1rem;
    }

    .skill-logo {
        font-size: 2rem;
    }

    .skill-name {
        font-size: 1.5rem;
    }
}

@media (max-width: 380px) {
    #skills > h2 {
        font-size: 1.7rem;
        margin-bottom: 0.5rem;
    }

    .skill {
        padding: 0.7rem;
    }

    .skill-logo {
        font-size: 1.5rem;
    }

    .skill-name {
        font-size: 1.5rem;
    }

    .skill-description {
        font-size: 1.3rem;
    }
}
