@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* favicon: https://www.flaticon.com/free-icon/personal-development_1429492?term=personal%20website&page=1&position=21&page=1&position=21&related_id=1429492 */
    /* favicon author: Maxim Basinski Premium*/

    /* background-image: https://www.freepik.com/free-vector/vector-background-abstract-polygon-triangles_1306609.htm#query=grey%20triangles%20background&position=2&from_view=keyword */
    /* background-image author: Ikatod */
}

html {
    /* set font size to 10px */
    font-size: 62.5%;
    font-family: 'Source Sans Pro', Montserrat, 'Lucida Sans', 'Lucida Sans Regular',
        'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

    /* height: 100%;
    overflow: hidden; */
}
/* ::-webkit-scrollbar {
    width: 0;
    background: transparent;
} */

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Source Sans Pro', 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-image: url('C:\projects\personal_webpage\personal_website\src\Assets\Images\background_tile.jpg');
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
