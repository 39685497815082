header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: sticky;
    top: 0;

    height: 70px;
    background-color: rgba(255, 255, 255, 0.349);
    backdrop-filter: blur(10px);

    z-index: 2;
}

h1 {
    /* color: rgb(0, 0, 112); */
    color: rgb(25, 39, 142);
    /* font-style: italic; */
    font-size: 5rem;
    padding-left: 2rem;
}

nav {
    font-size: 2rem;
    font-weight: bold;
    padding-right: 2rem;
}

nav > ul {
    display: flex;
    gap: 4rem;
}

nav > ul > li {
    list-style-type: none;
    cursor: pointer;
}

nav > ul > li:hover {
    transition: color 500ms;
    transition: box-shadow 500ms;
    color: rgb(25, 39, 142);
    box-shadow: inset 0 -2px 0 rgb(25, 39, 142);
}

.active-section {
    color: rgb(25, 39, 142);
    box-shadow: inset 0 -2px 0 rgb(25, 39, 142);
}
@media (max-width: 1000px) {
    h1 {
        font-size: 4rem;
    }

    nav {
        font-size: 1.5rem;
    }

    nav > ul {
        gap: 3rem;
    }
}

@media (max-width: 800px) {
    h1 {
        font-size: 3rem;
    }

    nav > ul {
        gap: 2.5rem;
    }
}

@media (max-width: 650px) {
    h1 {
        font-size: 2.5rem;
    }

    nav > ul {
        gap: 2rem;
    }
}

@media (max-width: 570px) {
    h1 {
        font-size: 2.5rem;
        word-spacing: 100vw;
    }

    nav {
        font-size: 1.5rem;
        padding-right: 1rem;
    }

    nav > ul {
        gap: 1.5rem;
    }
}

@media (max-width: 430px) {
    nav > ul {
        gap: 1rem;
    }
}

@media (max-width: 380px) {
    h1 {
        font-size: 2rem;
    }

    nav {
        font-size: 1.3rem;
    }
}
